import React, { Component } from "react";
import physicsHelpers from "./canvas/physicsHelpers";

class FireworksCanvas extends Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.drawScene = this.drawScene.bind(this);
    this.setCanvasRef = this.setCanvasRef.bind(this);
  }

  componentDidMount() {
    let canvas = this.canvas;
    // Start timer that adds animated items one at a time
    if (canvas) {
      this.drawScene();
    }
  }

  click() {
    const audioElement = document.querySelector("audio");
    audioElement.volume = 0.05;
    audioElement.play();
  }


  drawScene() {
    // Draw canvas for this animation frame
    this.resize();
    physicsHelpers.fireworks.drawLoop(this.canvas);
    // Draw next frame
    // setTimeout(() => requestAnimationFrame(this.drawScene),500);
  }

  resize() {
    if (this.canvas) {
      var gl = this.canvas.getContext("2d");
      var realToCSSPixels = window.devicePixelRatio;
      // Lookup the size the browser is displaying the canvas in CSS pixels
      // and compute a size needed to make our drawingbuffer match it in
      // device pixels.
      var displayWidth = Math.floor(gl.canvas.clientWidth * realToCSSPixels);
      var displayHeight = Math.floor(gl.canvas.clientHeight * realToCSSPixels);
      // Check if the canvas is not the same size.
      if (
        gl.canvas.width !== displayWidth ||
        gl.canvas.height !== displayHeight
      ) {
        // Make the canvas the same size
        gl.canvas.width = displayWidth;
        gl.canvas.height = displayHeight;
      }
    }
  }

  setCanvasRef(canvas) {
    this.canvas = canvas;
  }

  render() {
    return <canvas onClick={this.click} style={{ "position": "fixed", "height": "100vh", "width": "100vw", "top": 0 }} ref={this.setCanvasRef}><audio src="pop.mp3"></audio></canvas>;
  }
}

export default FireworksCanvas;
