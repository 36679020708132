import React, { Component } from 'react';
import './SLDSIcon.css';

class SLDSIcon extends Component {
  render() {
    const className = this.props.className || "";
    const defaultSrc = "/assets/icons/utility-sprite/svg/symbols.svg";
    let src = this.props.src || defaultSrc;
    return (
      <svg viewBox="0 0 60 55" width="50" height="50" className={"slds-icon slds-icon-text-default " + className} aria-hidden="true">
        <use xlinkHref={`${src}#${this.props.iconName}`}></use>
      </svg>
    );
  }
}

export default SLDSIcon;