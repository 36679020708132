import React, { Component } from 'react';
import "./ButtonIcon.css"
import SLDSIcon from './SLDSIcon';

class ButtonIcon extends Component {

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.openClick = this.openClick.bind(this);
  }


  openClick(e) {
    // For mobile safari focus button when clicked so menu closes when loses focus
    if (this.buttonRef && this.buttonRef.current) this.buttonRef.current.focus();
    if (typeof (this.props.onClick) === "function") {
      this.props.onClick(e);
    }
  }


  render() {
    let className = this.props.className || "";
    return <button type="button" tabIndex="-1" disabled={this.props.disabled} ref={this.buttonRef} onBlur={this.props.onBlur} onClick={this.openClick} className={`slds-button ${className}`} title={this.props.label}>
      {this.props.iconName ?
        <SLDSIcon src={this.props.src} iconName={this.props.iconName} />
        :
        ""
      }
      {this.props.children}
    </button>;
  }
}

export default ButtonIcon;