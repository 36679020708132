import React, { Component } from 'react';
import "./LoadingStencil.css"

class LoadingStencil extends Component {

  render() {
    return <div className="centro-loading-stencil">
      <div className="centro-position-absolute">
      <p>
        Hi Bailey,
      </p>
      <p>
        I was investigating you and noticed your run HR at Centro. Lakeview provides the easiest, lowest cost supplemental health insurance, and employees love us, and they’ll love you for signing up! Our product, <strong>Lilac</strong> provides your employees with the best options for compensation when you’re injured or can’t go to work! Here’s what you get covered when you sign up:
      </p>
      <ul>
        <li>On the job injuries</li>
        <li>Sick time compensation but you’ve already exhausted your sick time</li>
        <li>Injuries that don’t happen on the job, but keep you from your job</li>
        <li>Mental health days</li>
      </ul>
      <p>
        We’re excited to get you started, can we set up a time to talk to discuss our offerings and see if it’s a match?
      </p>
      <p>
        Warmly,<br />
        Hanna Bellevue<br />
        Business Development
        </p>
      </div>
    </div>
  }

}

export default LoadingStencil;