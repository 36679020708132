import React, { Component } from 'react';
import "./404.css";

class NotFound extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.centro.rocks/js/navigation.js";
    document.head.appendChild(script);
  }

  render() {
    return <div>
      <navigation-header>
        <a slot="links" href="/index.html">Home</a>
        <a slot="links" href="https://medium.com/@centro">Blog</a>
        <a slot="links" href="/pricing.html">Pricing</a>
        <a slot="links" href="/support.html">Support</a>
      </navigation-header>
      <div className="centro-404-container">
        <div className="center">
          <h1>404</h1>
          <img alt="empty state" src="/assets/images/illustrations/empty-state-assistant.svg" />
          <h2>Oops! The page you're looking for isn't here.</h2>
          <h3>You might have the wrong address, or the page may have moved.</h3>
          <a id="centro-button-link" class="centro-button-link" href="https://www.centro.rocks">Go back Home</a>
        </div>
      </div>
    </div>
  };
}

export default NotFound;