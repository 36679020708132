import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.css';
import Editor from './Editor';
import Installed from './Installed';
import Payment from './Payment';
import NotFound from './404';
import * as serviceWorker from './serviceWorker';
import InstallError from './InstallError';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/e/:teamid/" component={Editor} />
      <Route path="/welcome" component={Installed} />
      <Route path="/error" component={InstallError} />
      <Route path="/payment/:teamid" component={Payment} />
      <Route path="/" component={NotFound} />
    </Switch>
  </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
