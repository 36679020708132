import React, { Component } from 'react';
import MergeTagMenu from "./MergeTagMenu";
import LoadingStencil from "./LoadingStencil";
import httpHelpers from './httpHelpers.js';
import "./Editor.css";
import TuiEditor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';



const TIMEOUT_MESSAGE = "Whoops - for your security editing sessions time-out after 72 hours and can no longer be used.";
const WARN_CONFLICT_MESSAGE = "Message has been edited by another user do you want overwrite changes?";

class Editor extends Component {
  constructor(props) {
    super(props);
    this.enableRealTimeEditing = this.enableRealTimeEditing.bind(this);
    this.reload = this.reload.bind(this);
    this.conflict = this.conflict.bind(this);
    this.editorRefReady = this.editorRefReady.bind(this);
    this.onInsertText = this.onInsertText.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.setMarkdownValue = this.setMarkdownValue.bind(this);
    this.save = this.save.bind(this);
    this.getQueryString = this.getQueryString.bind(this);
    this.templateNameChanged = this.templateNameChanged.bind(this);
    this.emailSubjectChanged = this.emailSubjectChanged.bind(this);
    this.setMessageDetails = this.setMessageDetails.bind(this);
    this.emailToChanged = this.emailToChanged.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRealtimeUpdate = this.onRealtimeUpdate.bind(this);
    this.closeClick = this.closeClick.bind(this);
    this.ccChanged = this.ccChanged.bind(this);
    this.bccChanged = this.bccChanged.bind(this);
    this.phoneChanged = this.phoneChanged.bind(this);
    this.state = {
      templateName: "",
      emailSubject: ""
    };
  }

  getUrl() {
    // Url that we get/post data to
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("url") || "https://api.centro.rocks/v1/editor";
  }

  setMessageDetails(name, subject, email, cc, bcc, phone) {
    if (name !== undefined) {
      this.setState({
        templateName: name,
      });
    }
    if (subject !== undefined) {
      this.setState({
        emailSubject: subject,
      });
    }
    if (email !== undefined) {
      this.setState({
        emailTo: email
      });
    }
    if (cc !== undefined) {
      this.setState({
        cc: cc
      });
    }
    if (bcc !== undefined) {
      this.setState({
        bcc: bcc
      });
    }
    if (phone !== undefined) {
      this.setState({
        phone: phone
      });
    }
  }

  getQueryString() {
    const teamid = this.props && this.props.match && this.props.match.params && this.props.match.params.teamid;
    const urlParams = new URLSearchParams(window.location.search);
    // Check if enough parameters to make request for mrkdwn data
    const channelid = urlParams.get("c") || undefined;
    const userid = urlParams.get("u") || undefined;
    const ts = urlParams.get("ts") || undefined;
    const templateid = urlParams.get("tid") || undefined;
    const thread = urlParams.get("thread") || undefined;
    // Sig/ts used for time-limited authentication
    const sig = urlParams.get("s");
    const sigTs = urlParams.get("sts");
    let getParams = {};
    // Url can be passed in for use in multiple environments
    if (teamid && sig && sigTs) {
      if (channelid && ts) {
        // Get mrkdwn from message
        getParams = {
          t: teamid,
          u: userid,
          c: channelid,
          ts: ts,
          thread: thread,
          s: sig,
          sts: sigTs
        };
        return httpHelpers.createQueryString(getParams);
      }
      else {
        // Get mrkdwn from template
        getParams = {
          t: teamid,
          u: userid,
          c: channelid,
          tid: templateid,
          s: sig,
          sts: sigTs
        };
      }
      return httpHelpers.createQueryString(getParams);
    }
  }

  reload() {
    const qs = this.getQueryString();
    if (qs) {
      const url = this.getUrl();
      this.setMarkdownValue("");
      this.loadValue(`${url}?${qs}`);
    }
  }


  componentDidMount() {
    this.reload();
    // Set tabIndex on editor containers 
    const toolbarButtons = document.getElementsByClassName("tui-editor-contents");
    if (toolbarButtons.length > 0) {
      for (var i = 0; i < toolbarButtons.length; i++) {
        toolbarButtons[i].tabIndex = i + 2;
      }
    }
  }

  loadValue(endpoint) {
    this.setState({
      isLoading: true
    });
    httpHelpers.request(endpoint).then(data => {
      this.setState({
        isLoading: false,
        hashCode: data.hashCode
      });
      this.setMessageDetails(data.name, data.subject, data.to, data.cc, data.bcc, data.phone);
      if (data && data.mrkdwn) {
        this.setMarkdownValue(data.mrkdwn);
        this.enableRealTimeEditing();
      }
    }).catch(err => {
      this.setState({
        isLoading: false
      });
      if (err && err.status === 401) {
        // Unauthorized - likely signature timeout
        alert(TIMEOUT_MESSAGE);
      }
      console.error(err);
    });
  }

  editorRefReady(editorElement) {
    this.editor = new TuiEditor({
      el: editorElement,
      initialEditType: 'wysiwyg',
      hideModeSwitch: true,
      toolbarItems: [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['link'],
        ['code', 'codeblock']
      ],
      initialValue: this.props.initialValue,
    });
    window.removeEventListener('beforeunload', this.onClose);
    window.addEventListener('beforeunload', this.onClose);
  }

  onRealtimeUpdate() {
    // Check if content has changed
    clearTimeout(this.updateTimer);
    if (this.editor) {
      const html = this.editor.getHTML();
      if (this.priorValue && this.priorValue !== html) {
        this.save().finally(() => {
          this.updateTimer = setTimeout(this.onRealtimeUpdate);
        });
      }
      else {
        this.updateTimer = setTimeout(this.onRealtimeUpdate, 500)
      }
      this.priorValue = html;
    }
  }

  onClose(e) {
    // Cancel any pending save timer
    clearTimeout(this.updateTimer);
    // Make a final save and busy wait for it to complete
    let waiting = true;
    this.save(true).catch(err => {
      console.error(err);
    }).finally(x => {
      waiting = false;
    });
    let startTicks = new Date().valueOf();
    while (waiting) {
      // Busy wait for up to limit until save completes
      let nowTicks = new Date().valueOf();
      let diff = nowTicks - startTicks;
      if (diff > 500) {
        waiting = false;
      }
    }
  }

  onInsertText(text) {
    if (this.editor && this.editor.insertText) {
      this.editor.insertText(`[[${text}]]`);
    }
  }

  setMarkdownValue(markdown) {
    if (this.editor && this.editor.setMarkdown) {
      this.editor.setMarkdown(markdown);
    }
  }

  closeClick() {
    clearTimeout(this.updateTimer);
    let is_ios = /(iPhone|iPod|iPad).*AppleWebKit.*Safari/i.test(navigator.userAgent);
    if (is_ios) {
      // For slack mobile app closes in app web view
      this.save(true);
      window.location = `slack://app`;
    }
    else {
      // Close window
      window.close();
    }
  }

  conflict(o) {
    if (o && o.error === "CONFLICT") {
      // Cancel any pending save timer
      clearTimeout(this.updateTimer);
      // Show conflict message to user to overwrite/reload/cancel
      if (window.confirm(WARN_CONFLICT_MESSAGE)) {
        this.save(false, true).finally(() => this.enableRealTimeEditing());
      }
      else {
        // Reload changes
        this.reload();
      }
    }
  }

  enableRealTimeEditing() {
    // If editing a message type implement real-time updates with an update timer
    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get("ts");
    const enableRealtime = ts && ts.length > 0;
    if (enableRealtime) {
      this.onRealtimeUpdate();
    }
  }

  save(isClosing, overwrite) {
    const qs = this.getQueryString();
    if (qs && this.editor && this.editor.getHTML) {
      const url = [this.getUrl(), qs].join("?");
      const html = this.editor.getHTML();
      const postData = {
        body: html || "",
        name: this.state.templateName,
        sub: this.state.emailSubject,
        email: this.state.emailTo,
        phone: this.state.phone,
        cc: this.state.cc,
        bcc: this.state.bcc,
        isClosing: isClosing,
        hashCode: overwrite ? undefined : this.state.hashCode
      };
      const formData = httpHelpers.createQueryString(postData);
      // Post data to endpoint
      return httpHelpers.request(url, "POST", formData, "application/x-www-form-urlencoded; charset=utf-8")
        .then(obj => {
          // Successful save update hashCode to verify next save
          if (obj.hashCode) {
            this.setState({
              hashCode: obj.hashCode
            });
          }
        })
        .catch(err => {
          if (err && err.status === 401) {
            // Unauthorized - likely signature timeout
            alert(TIMEOUT_MESSAGE);
          }
          if (err && err.status === 400) {
            err.json().then(this.conflict);
          }
          console.error(err);
        });
    }
  }

  ccChanged(e) {
    this.setState({
      cc: e.target.value
    });
  }

  bccChanged(e) {
    this.setState({
      bcc: e.target.value
    });
  }

  templateNameChanged(e) {
    this.setState({ templateName: e.target.value });
  }

  emailSubjectChanged(e) {
    this.setState({ emailSubject: e.target.value });
  }

  emailToChanged(e) {
    this.setState({ emailTo: e.target.value });
  }

  phoneChanged(e) {
    this.setState({ phone: e.target.value });
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const isTemplate = urlParams.get("ts") === null;
    return <div className="centro-flex-vertical">
      <div className="centro-button-bar">
        <form onSubmit={this.closeClick}>
          <div className="centro-flex-horizontal-reverse">
            <input type="submit" value="Save" className="centro-button centro-button-primary"></input>
            {isTemplate ?
              <MergeTagMenu onSelectItem={this.onInsertText}></MergeTagMenu>
              :
              ""
            }
          </div>
          {isTemplate ?
            <div className="centro-input">
              <label htmlFor="centro-template-name">Template Name</label>
              <div>
                <input tabIndex="1" onChange={this.templateNameChanged} value={this.state.templateName} id="centro-template-name" type="text" required></input>
              </div>
              <label htmlFor="centro-email-subject">Email Subject</label>
              <div>
                <input tabIndex="2" onChange={this.emailSubjectChanged} value={this.state.emailSubject} id="centro-email-subject" type="text" required></input>
              </div>
            </div>
            :
            (typeof (this.state.emailTo) === "string" ?
              <div className="centro-input">
                <label htmlFor="centro-email-to">To</label>
                <div>
                  <input tabIndex="1" onChange={this.emailToChanged} value={this.state.emailTo} id="centro-email-to" type="text" required></input>
                </div>
                <label htmlFor="centro-cc">Cc</label>
                <div>
                  <input tabIndex="2" onChange={this.ccChanged} value={this.state.cc} id="centro-cc" type="text"></input>
                </div>
                <label htmlFor="centro-bcc">Bcc</label>
                <div>
                  <input tabIndex="3" onChange={this.bccChanged} value={this.state.bcc} id="centro-bcc" type="text"></input>
                </div>
                <label htmlFor="centro-email-subject">Email Subject</label>
                <div>
                  <input tabIndex="4" onChange={this.emailSubjectChanged} value={this.state.emailSubject} id="centro-email-subject" type="text"></input>
                </div>
              </div>
              : (
                <div className="centro-input">
                  <label htmlFor="centro-phone">Phone</label>
                  <div>
                    <input tabIndex="1" onChange={this.phoneChanged} value={this.state.phone} id="centro-phone" type="text" required></input>
                  </div>
                </div>
              )
            )
          }
        </form>
      </div>
      {this.state.isLoading === true ?
        <LoadingStencil />
        :
        ""
      }
      <div className="centro-editor" ref={this.editorRefReady} />
    </div>;
  }
}

export default Editor;