import React, { Component } from 'react';
import ButtonIcon from './ButtonIcon'
import "./Menu.css";

class Menu extends Component {

  constructor(props) {
    super(props);
    this.getComponent = this.getComponent.bind(this);
    this.openClick = this.openClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.dragLeave = this.dragLeave.bind(this);
    this.containerRef = React.createRef();
    this.state = {};
  }

  openClick() {
    this.setState({
      open: !this.state.open
    });
    if (typeof (this.props.onOpen) === "function") {
      this.props.onOpen();
    }
  }

  onBlur(e) {
    if (!this.ignoreBlur) {
      if ((this.props.remainOpen && !e.relatedTarget) || (this.containerRef && this.containerRef.current && this.containerRef.current.contains(e.relatedTarget))) {
        // Clicked subitem start recursively polling until activeElement is not submenu item then close
        setTimeout(() => {
          this.onBlur({
            relatedTarget: document.activeElement
          });
        }, 300);
      }
      else {
        // clicked outside menu
        setTimeout(() => {
          this.setState({
            open: false
          });
        });
      }
    }
  }

  getComponent(key) {
    return this.props.children.filter((comp) => {
      return comp.key === key;
    });
  }

  dragLeave(e) {
    let that = this;
    if (this.state.open) {
      setTimeout(() => {
        that.ignoreBlur = true;
        document.activeElement.blur();
        that.ignoreBlur = undefined;
        this.setState({
          open: false
        });
      }, 300);
    }
  }

  render() {
    // eslint-disable-next-line
    let menuButton = <ButtonIcon iconName={this.props.iconName} className="centro-button centro-menu-button" onClick={this.openClick} onBlur={this.onBlur}>
      {this.getComponent("menuButton")}
    </ButtonIcon>;
    let className = this.props.className || "";
    return (<div ref={this.containerRef} className={(this.state.open ? "centro-is-open " : "") + "centro-menu " + className}>
      <div onDragLeave={this.dragLeave} className="centro-menu-items">
        {this.state.open ?
          this.getComponent("menuItems")
          : ""
        }
      </div>
      {menuButton}
    </div>);
  }
}

export default Menu;