var realToCSSPixels = window.devicePixelRatio;

/* Method to create a point object */
var rgb = function (r, g, b, a) {
  a = a === undefined ? 1 : a;
  return "rgba(" + (r | 0) + "," + (g | 0) + "," + (b | 0) + "," + a + ")";
};
var pointAt = function (x, y, z) {
  // Input validation & default values
  if (typeof x !== "number") {
    x = 0;
  }
  if (typeof y !== "number") {
    y = 0;
  }
  if (typeof z !== "number") {
    z = 0;
  }
  var ret = { x: x, y: y, z: z };
  /* Point methods */
  ret.add = function (point) {
    ret.x += point.x;
    ret.y += point.y;
    ret.z += point.z;
  };
  ret.subtract = function (point) {
    ret.x -= point.x;
    ret.y -= point.y;
    ret.z -= point.z;
  };
  ret.scale = function (factor) {
    ret.x *= factor;
    ret.y *= factor;
    ret.z *= factor;
  };
  ret.dotProduct = function (point) {
    return ret.x * point.x + ret.y * point.y + ret.z * point.z;
  };
  return ret;
};

var createEnvironment = function (width, height, friction, forceVector) {
  /* Input validation & default values */
  if (typeof width !== "number") {
    width = 640;
  }
  if (typeof height !== "number") {
    height = 480;
  }
  if (typeof friction !== "number") {
    friction = 0.01;
  }
  if (typeof forceVector !== "object") {
    forceVector = pointAt(0, 0);
  }

  var ret = {
    "width": width,
    "height": height,
    "friction": friction,
    force: forceVector,
    // Assumes top-left origin
    hasLeft: true, // left = x<0
    hasRight: true, // right = x>width
    hasTop: true, // top = y<0
    hasBottom: true // bottom = y>height
  };

  /* Method to test if a pointmass has passed out of this 
  container, and take action as needed. In this case,
  push pointmass back inside container (bounce off edges).
  Return true if outside containing edges. */
  ret.edgeTest = function (pm) {
    var ret = false;
    if (this.hasLeft && pm.x < 0) {
      ret = true;
      pm.prev.x = pm.x;
      pm.x = 0;
    }
    else if (this.hasRight && pm.x > width) {
      ret = true;
      pm.prev.x = pm.x;
      pm.x = width;
    }
    if (this.hasBottom && pm.y > height - pm.size) {
      ret = true;
      pm.prev.y = pm.y;
      pm.y = height - pm.size + 1;
    }
    else if (this.hasTop && pm.y < pm.size) {
      ret = true;
      pm.prev.y = pm.y;
      pm.y = pm.size;
    }
    return ret;
  };

  return ret;
};

var pointMassAt = function (x, y, mass, environment, size) {
  /* PointMass specializes Point */
  var ret = pointAt(x, y);

  /* Input validation & default values */
  if (typeof mass !== "number") {
    mass = 1.0;
  }
  if (typeof environment !== "object") {
    environment = createEnvironment();
  }

  /* Setup instance variables */
  ret.prev = Object.create(ret); // var for holding prev xy position
  ret.mass = mass;
  ret.size = size;
  ret.environment = environment;

  /* Private variable and function to handle constraints */
  var constraints = [];
  var delta = pointAt(0, 0);
  var constrain = function (pointA, pointB, maxDist, minDist) {
    delta.x = pointA.x;
    delta.y = pointA.y;
    delta.subtract(pointB);
    var dotprod = delta.dotProduct(delta);
    if (typeof minDist === "number") {
      var k = minDist * minDist;
      if (dotprod < k) {
        // Only allow pointA to get minDist close to pointB
        delta.scale(k / (dotprod + k) - 0.5);
        pointB.subtract(delta);
        pointA.add(delta);
        const rand = Math.random();
        if (rand < 0.00001 && pointA.burst === false) {
          pointA.burst = true;
          pointA.prev.x += (Math.random() * 300) - 150;
          pointA.prev.y += (Math.random() * 300) - 150;
          const audioElement = document.querySelector("audio");
          audioElement.volume = 0.05;
          audioElement.play();
          setTimeout(() => pointA.size = 0, 5000);
        }
      }
    }
    if (typeof maxDist === "number") {
      k = maxDist * maxDist;
      if (dotprod > k) {
        // Only allow pointA to get minDist far from pointB
        delta.scale(k / (dotprod + k) - 0.5);
        pointB.subtract(delta);
        pointA.add(delta);
      }
    }
  };
  ret.constraints = constraints;

  /* Add a constraint from this point to specified point and distance */
  ret.addConstraint = function (point, minDist, maxDist) {
    if (typeof maxDist !== 'number') {
      maxDist = minDist;
    }
    constraints.push({
      "point": point,
      "minDist": minDist,
      "maxDist": maxDist
    });
  };

  /* Approximates points position in the future time dt based on previous position,
  environment, and contraints that have been added. */
  ret.tick = function (dt) {
    var dtdt = dt * dt;
    var verlet = function (x) {
      // calc new x using Verlet integration
      var a = ret.environment.force[x] / ret.mass; // acceleration
      // (2-c) * x - (1-c) * [x-1] * a * dt^2
      var t = (2 - ret.environment.friction) * ret[x] - (1 - ret.environment.friction) * ret.prev[x] + a * dtdt;
      ret.prev[x] = ret[x];
      ret[x] = t;
    };
    /* Perform verlet integration in all dimensions */
    verlet("x");
    verlet("y");

    /* Perform actions if environment bounds have been crossed */
    ret.environment.edgeTest(this);

    /* Enforce any constraints with other Points that have been set */
    for (var i = 0; i < constraints.length; i++) {
      constrain(ret, constraints[i].point, constraints[i].minDist, constraints[i].maxDist);
    }
  };

  return ret;
}
var fireworks = {
  drawLoop: function (canvas) {
    var playing = true;
    var width = canvas.width;
    var height = canvas.height;
    var time = 0;
    var frame = 0;
    var canvas2d = canvas.getContext("2d");
    var sprites = [];
    var environment = createEnvironment(width, height, 0.03, pointAt(0, -500));
    environment.context = canvas2d;
    environment.hasTop = true;
    environment.hasBottom = false;
    environment.hasLeft = false;
    environment.hasRight = false;

    // Create a lot of confetti sprites
    // var half = (width / 2);
    var num = parseInt(Math.random() * 100, 10) + 10;
    var cursorSprite = pointMassAt(0, 0, 1, environment, 20 * realToCSSPixels);
    cursorSprite.draw = (pen) => {
      // pen.save();
      // pen.beginPath();
      // pen.arc(cursorSprite.x, cursorSprite.y, cursorSprite.size * realToCSSPixels, 0, 2 * Math.PI, false);
      // pen.stroke();
      // pen.restore();
    };
    cursorSprite.tick = () => { };
    document.addEventListener("mousemove", (e) => {
      cursorSprite.x = e.clientX * realToCSSPixels;
      cursorSprite.y = e.clientY * realToCSSPixels;
    });
    sprites.push(cursorSprite);
    var fire = function () {
      var sprite = fireworks.fireworksSpriteAt(Math.random() * width, height + 300, 100, environment);
      sprites.forEach(sp => {
        sprite.addConstraint(sp, undefined, (sprite.size + sp.size) * realToCSSPixels * 0.8);
      });
      sprite.prev.x = sprite.x;
      sprite.prev.y = sprite.y;
      sprites.push(sprite);
      if (num-- > 0) {
        setTimeout(fire, Math.random() * 1500);
      }
    };
    fire();
    var drawFrame = function (timestamp) {
      if (playing) {
        requestAnimationFrame(drawFrame);
      }
      time = frame / 60;
      if (time * 60 | 0 === frame - 1) {
        time += 0.000001;
      }
      frame++;
      environment.context.fillStyle = "#fdf8f4";
      environment.context.fillRect(0, 0, environment.width, environment.height);
      // var victims = [];
      for (var i = 0; i < sprites.length; i++) {
        sprites[i].tick(0.1);
        sprites[i].draw(environment.context);
        // if (sprites[i].burst !== startBurst) {
        //   burst(sprites[i]);
        //   break;
        // }
        // else if (sprites[i].burst && sprites[i].time > (500 + (Math.random() * 250))) {
        //   victims.push(sprites[i]);
        // }
      }
      // victims.forEach(function (p) {
      //   sprites.splice(sprites.indexOf(p), 1);
      // });
    };
    requestAnimationFrame(drawFrame);
  },
  fireworksSpriteAt: function (x, y, mass, environment, pointSize) {

    mass += (Math.random());
    var ret = pointMassAt(x, y, mass, environment);
    if (typeof pointSize !== "number") {
      pointSize = 20;
    }
    var red = rgb(239, 141, 80);
    var gradred = rgb(235, 67, 58);
    var purple = rgb(121, 113, 217);
    var gradpurple = rgb(69, 90, 244);
    var gradclear = rgb(226, 221, 221, 0.15);
    var clear = rgb(231, 221, 221, 0.75);


    ret.size = 12 + Math.round(50 * Math.random());

    var colors = [[clear, gradclear], [red, gradred], [purple, gradpurple], [clear, gradclear], [clear, gradclear], [clear, gradclear], [clear, gradclear]];
    ret.color = colors[Math.round(Math.random() * (colors.length - 1))];
    ret.time = Math.random() * 1000;
    ret.burst = false;
    ret.draw = function (canvasCtx) {
      var x = Math.round(ret.x);
      var y = Math.round(ret.y);
      var pen = canvasCtx;

      var time = ret.time / 60;
      if (time * 60 | 0 === ret.time - 1) {
        time += 0.000001;
      }
      ret.time++;
      // var t = time;

      if (y > ret.prev.y) {
        // ret.burst = true;
      }

      // var S = Math.sin;
      // var C = Math.cos;
      if (!Array.isArray(ret.color)) debugger;
      if (!ret.burst) {
        pen.save();
        pen.beginPath();
        /* Draw dot */
        // pen.fillStyle = rgb(0, 0, 0, 0.5);
        // pen.arc(ret.prev.x, ret.prev.y, 2, 0, 2 * Math.PI, false);
        // pen.fill();
        const size = parseInt(ret.size * realToCSSPixels, 10);
        const grad = pen.createRadialGradient(x, y - parseInt(size * 0.25, 10), parseInt(size * 0.65, 10), x, y, size);
        // Add three color stops
        grad.addColorStop(0, ret.color[1]);
        grad.addColorStop(0.9, ret.color[0]);
        grad.addColorStop(1, "#fdf8f4");
        // pen.arc(x, y, size, 0, 2 * Math.PI, false);
        // pen.stroke();
        pen.arc(x, y, size, 0, 2 * Math.PI, false);
        pen.fillStyle = grad; //rgb.apply(window, ret.color);
        pen.fill();
        pen.closePath();
        pen.restore();
      }
    };

    return ret;
  }
};

export default {
  pointMassAt: pointMassAt,
  pointAt: pointAt,
  fireworks: fireworks
};
