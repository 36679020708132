import React, { Component } from 'react';
import "./404.css";

class InstallError extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.centro.rocks/js/navigation.js";
    document.head.appendChild(script);
    const install = document.createElement("script");
    install.src = "https://www.centro.rocks/js/install.js";
    document.head.appendChild(install);
  }

  render() {
    return <div>
      <navigation-header>
        <a slot="links" href="/index.html" class="active">Home</a>
        <a slot="links" href="about.html">About</a>
        <a slot="links" href="/blog.html">Updates</a>
        <a slot="links" href="/pricing.html">Pricing</a>
        <a slot="links" href="http://support.centro.rocks">Support</a>
        <install-button slot="float-right"></install-button>
      </navigation-header>
      <div className="centro-404-container">
        <div className="center">
          <h1>Installation Error</h1>
          <img alt="empty state" src="/assets/images/illustrations/empty-state-assistant.svg" />
          <h2>Oops! An unexpected error occured during installation.</h2>
          <h3>If this is a free Slack workspace it may have <a href="https://slack.com/help/articles/115002422943-Message-file-and-app-limits-on-the-free-version-of-Slack">exceeded the App installation limit</a>. Please try again.</h3>
          <div className="center">
            <install-button></install-button>
          </div>
        </div>
      </div>
    </div>
  };
}

export default InstallError;