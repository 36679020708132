import React, { Component } from 'react';
import httpHelpers from "./httpHelpers";
import PaymentMethod from "./PaymentMethod";
import ButtonIcon from "./ButtonIcon";
import SLDSIcon from './SLDSIcon';
import "./Payment.css";

const TIMEOUT_MESSAGE = "Whoops - it looks like this payment link has expired.";

const STRIPE_PUBLIC_KEY = "pk_live_pl0urBzjzhuNXXrsc775ulSP00a6YQ3Ocb";
const STRIPE_PUBLIC_TEST_KEY = "pk_test_8Fa0qYW2Zc3Dzk0sOa9oipVr00NlGeAcaR";
const PAYMENT_API_URL = "https://api.centro.rocks/v1/payment";
const STAGING_API_URL = "https://staging.centro.rocks/v1/payment";
const PLAID_ENVIRONMENT_DEVELOPMENT = "development";
const PLAID_ENVIRONMENT_PRODUCTION = "production";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.addCardClick = this.addCardClick.bind(this);
    this.addACHClick = this.addACHClick.bind(this);
    this.submitACH = this.submitACH.bind(this);
    this.removeCardClick = this.removeCardClick.bind(this);
    this.setUsers = this.setUsers.bind(this);
    this.setWorkspaceUsers = this.setWorkspaceUsers.bind(this);
    this.setTerm = this.setTerm.bind(this);
    this.setTier = this.setTier.bind(this);
    // this.submitClick = this.submitClick.bind(this);
    this.state = {
      isLoading: true,
      users: 1,
      workspaceUsers: 0,
      packages: [],
      tier: "Standard",
      term: 'Monthly'
    };
  }


  getQueryString() {
    const teamid = this.props && this.props.match && this.props.match.params && this.props.match.params.teamid;
    const urlParams = new URLSearchParams(window.location.search);
    const userid = urlParams.get("userid");
    // // Sig/ts used for time-limited authentication
    const sig = urlParams.get("sig");
    const sigTs = urlParams.get("sts");
    let getParams = {};
    // Url can be passed in for use in multiple environments
    if (teamid) {
      // Get mrkdwn from message
      getParams = {
        teamid: teamid,
        userid: userid,
        sig: sig,
        sts: sigTs
      };
      return httpHelpers.createQueryString(getParams);

    }
    return httpHelpers.createQueryString(getParams);
  }

  loadPaymentData(endpoint) {
    this.setState({
      isLoading: true
    });
    httpHelpers.request(endpoint).then(data => {
      this.setState({
        isLoading: false,
        sessionId: data.sessionId,
        cards: data.cards && data.cards.data,
        banks: data.banks && data.banks.data,
        pricing: data.pricing,
        link_token: data.link_token
      });
    }).catch(err => {
      this.setState({
        isLoading: false
      });
      if (err && err.status === 401) {
        // Unauthorized - likely signature timeout
        alert(TIMEOUT_MESSAGE);
      }
      console.error(err);
    });
  }


  getUrl() {
    // Url that we get/post data to
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("url") || PAYMENT_API_URL;
  }

  componentDidMount() {
    const qs = this.getQueryString();
    if (qs) {
      const url = this.getUrl();
      this.loadPaymentData(`${url}?${qs}`);
    }
  }

  addACHClick() {
    const script = document.createElement("script");
    const url = this.getUrl();
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    script.onload = () => {
      window.Plaid.create({
        env: (url === PAYMENT_API_URL || url === STAGING_API_URL) ? PLAID_ENVIRONMENT_PRODUCTION : PLAID_ENVIRONMENT_DEVELOPMENT,
        token: this.state.link_token,
        onExit: (err, metadata) => {
          console.error(err);
        },
        onSuccess: (public_token, metadata) => {
          this.submitACH(public_token, metadata && metadata.accounts && metadata.accounts[0] && metadata.accounts[0].id);
        }
      }).open();
    }
    document.head.appendChild(script);
  }

  addCardClick() {
    const script = document.createElement("script");
    const url = this.getUrl();
    script.src = "https://js.stripe.com/v3/";
    script.onload = () => {
      var stripe = window.Stripe(url === PAYMENT_API_URL ? STRIPE_PUBLIC_KEY : STRIPE_PUBLIC_TEST_KEY);
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.state.sessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result && result.error && result.error.message) {
          alert(result.error.message);
        }
      });
    }
    document.head.appendChild(script);
  }

  removeCardClick(id, last4) {
    if (window.confirm(`Remove payment method ending with ${last4}?`)) {
      const qs = this.getQueryString();
      const url = this.getUrl();
      const endpoint = `${url}?${qs}&pmid=${encodeURIComponent(id)}`;
      httpHelpers.request(endpoint, "DELETE").then(() => {
        this.loadPaymentData(`${url}?${qs}`);
      }).catch(err => {
        if (err && err.status === 401) {
          // Unauthorized - likely signature timeout
          alert(TIMEOUT_MESSAGE);
        }
        console.error(err);
      });
    }
  }

  closeClick(e) {
    window.close();
  }

  shareClick(e) {
    window.navigator.clipboard.writeText(window.location.href);
  }

  setTerm(e) {
    this.setState({
      packages: [],
      term: e.target && e.target.value
    });
  }

  setTier(e) {
    this.setState({
      tier: e.target && e.target.value
    });
  }

  setWorkspaceUsers(e) {
    this.setState({
      workspaceUsers: parseInt(e.target && e.target.value, 10)
    });
  }

  setUsers(e) {
    this.setState({
      users: parseInt(e.target && e.target.value, 10)
    });
  }

  async submitACH(public_token, account_id) {
    const qs = this.getQueryString();
    const url = this.getUrl();
    const endpoint = `${url}?${qs}`;
    const postData = JSON.stringify({
      public_token: public_token,
      account_id: account_id
    });
    try {
      await httpHelpers.request(endpoint, "PATCH", postData);
      this.loadPaymentData(`${url}?${qs}`);
    }
    catch (err) {
      if (err && err.status === 401) {
        // Unauthorized - likely signature timeout
        alert(TIMEOUT_MESSAGE);
      }
    }
  }

  // async submitClick(e) {
  //   let button = e.target;
  //   button.disabled = true;
  //   const qs = this.getQueryString();
  //   const url = this.getUrl();
  //   const endpoint = `${url}?${qs}`;
  //   let isMonthly = this.state.term === "Monthly";
  //   let slackPricebook = this.state.pricing.find(x => (x.Product2.Recurrence_Type__c === "Monthly") === isMonthly && x.Product2.Type__c === 'Individual Workspace User' && x.Product2.Product_Tier__c === this.state.tier);
  //   let salesforcePricebook = this.state.pricing.find(x => (x.Product2.Recurrence_Type__c === "Monthly") === isMonthly && x.Product2.Type__c === 'Individual Connected User' && x.Product2.Product_Tier__c === this.state.tier);
  //   const postData = JSON.stringify({
  //     users: this.state.users,
  //     workspaceUsers: this.state.workspaceUsers,
  //     priceEntryIds: [slackPricebook.Id, salesforcePricebook.Id],
  //   });
  //   try {
  //     await httpHelpers.request(endpoint, "POST", postData);
  //     // redirect to welcome page
  //     window.location = '/welcome?message=' + encodeURIComponent("THANK YOU for your Order!");
  //   }
  //   catch (err) {
  //     if (err && err.status === 401) {
  //       // Unauthorized - likely signature timeout
  //       alert(TIMEOUT_MESSAGE);
  //     }
  //   }
  //   button.disabled = false;
  // }

  render() {
    const cards = this.state.cards;
    const banks = this.state.banks;
    const paymentMethods = [];
    if (Array.isArray(cards)) {
      cards.forEach(x => {
        paymentMethods.push(<PaymentMethod onRemove={this.removeCardClick} key={x.id} data={x} />);
      });

    }
    if (Array.isArray(banks)) {
      banks.forEach(x => {
        paymentMethods.push(<PaymentMethod onRemove={this.removeCardClick} key={x.id} data={x} />);
      });
    }
    // Create our number formatter.
    // var currency = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD',
    // });
    // let slackPricing = "";
    // let salesforcePricing = "";
    // let isMonthly = this.state.term === "Monthly";
    // let summaryUsers = "";
    // let summarySlackUsers = "";
    // let summaryPackages = "";
    // let summaryPlan = "";
    // let summaryTotal = "Total: ";
    // if (Array.isArray(this.state.pricing)) {
    //   let slackPricebook = this.state.pricing.find(x => (x.Product2.Recurrence_Type__c === "Monthly") === isMonthly && x.Product2.Type__c === 'Individual Workspace User' && x.Product2.Product_Tier__c === this.state.tier);
    //   let salesforcePricebook = this.state.pricing.find(x => (x.Product2.Recurrence_Type__c === "Monthly") === isMonthly && x.Product2.Type__c === 'Individual Connected User' && x.Product2.Product_Tier__c === this.state.tier);
    //   let formatMonthlyPrice = (priceEntry) => {
    //     let price = priceEntry.UnitPrice;
    //     // Show annual prices in monthly unit
    //     if (isMonthly === false) price = currency.format(price / 12);
    //     else price = currency.format(price);
    //     return price + "/month";
    //   };
    //   if (slackPricebook) {
    //     slackPricing = formatMonthlyPrice(slackPricebook);
    //   }
    //   if (salesforcePricebook) {
    //     salesforcePricing = formatMonthlyPrice(salesforcePricebook);
    //   }
    //   let salesforcePrice = 0, slackPrice = 0;
    //   if (this.state.users > 0) {
    //     if (salesforcePricebook && salesforcePricebook.UnitPrice) {
    //       salesforcePrice = salesforcePricebook.UnitPrice;
    //     }
    //     summaryUsers = `${this.state.users} x Salesforce users at ${currency.format(salesforcePrice)}`;
    //   }
    //   if (this.state.workspaceUsers > 0) {
    //     if (slackPricebook && slackPricebook.UnitPrice) {
    //       slackPrice = slackPricebook.UnitPrice;
    //     }
    //     summarySlackUsers = `${this.state.workspaceUsers} x Slack users at ${currency.format(slackPrice)}`;
    //   }
    //   if (this.state.users > 0) {
    //     summaryPackages = this.state.tier;
    //     // summaryPlan = this.state.packages.map(x => x && x.Product2.Description).join(" ");
    //     summaryTotal += `${currency.format((slackPrice * (this.state.workspaceUsers > 0 ? this.state.workspaceUsers : 0)) + (salesforcePrice * this.state.users))} USD billed ${this.state.term}`;
    //   }
    // }

    // const disabled = !(this.state.users > 0 && paymentMethods && paymentMethods.length > 0);
    return <div className="centro-profile-window">
      <div className="centro-payment-info">
        <h1>Securely add a payment method</h1>
        <div className="centro-payment-section">
          {/* <div>
            <h2>Before you start:</h2>
          </div> */}

        </div>
        <div className="centro-payment-section">
          <div className="centro-align-center">
            <div>
              <h4>if another person needs to setup payment <a href="#" onClick={this.shareClick}><SLDSIcon iconName="copy_to_clipboard" /> share this page</a> valid for 72 hours.</h4>
            </div>
          </div>
        </div>
        <div className="centro-payment-section">
          <div>
            <h2>Add card or bank account</h2>
          </div>
          <div>
            <ButtonIcon onClick={this.addCardClick} iconName="add" label="Add Card">Add Card</ButtonIcon>
          </div>
        </div>
        <div className="centro-button-section">
          <div></div>
          <div>
            <ButtonIcon onClick={this.addACHClick} iconName="add" label="Add Card">Add Bank</ButtonIcon>
          </div>
        </div>

        <div className="centro-payment-section">
          <div className="centro-align-center">
            {this.state.isLoading ?
              "Loading..."
              :
              <div>
                {paymentMethods && paymentMethods.length > 0 ?
                  paymentMethods
                  :
                  <div className="centro-payment-unavailable">
                    <h4>No Payment Method</h4>
                    Centro will not be available for use at end of Billing period.
                    <img className="stripe-logo" src="/Stripe.png"></img>
                  </div>
                }
              </div>
            }
          </div>
        </div>

        {paymentMethods && paymentMethods.length > 0 ?
          <div className="centro-payment-section">
            <div className="centro-align-center">
              <button onClick={this.closeClick}>Close</button>
            </div>
          </div>
          :
          ""}

        {/* <div className="centro-payment-section">
          <div>
            <h2>Step 2: Pay monthly or annually?</h2>
          </div>
          <div>
            <select value={this.state.term} onChange={this.setTerm}>
              <option>Monthly</option>
              <option>Annually</option>
            </select>
          </div>
        </div>
        <div className="centro-payment-section">
          <div>
            <h2>Step 3: Select Plan</h2>
            {summaryPlan && summaryPlan.length > 0 ?
              <div style={{ "whiteSpace": "break-spaces" }}>
                <div>Included Users:</div>
                {summaryPlan}
              </div>
              : ""}
            <div>
              <a href="https://www.centro.rocks/pricing" rel="noopener noreferrer" target="_blank">Pricing Details</a>
            </div>
            <div>
              <a href="https://bit.ly/3zHPWE8" rel="noopener noreferrer" target="_blank">Contact Sales</a>
            </div>
          </div>
          <div>
            <select value={this.state.tier} onChange={this.setTier}>
              <option>Standard</option>
              <option>Enterprise</option>
            </select>
          </div>
        </div>
        <div className="centro-payment-section">
          <div>
            <h2>Step 4: Number of users?</h2>
          </div>
        </div>
        <div className="centro-payment-section">
          <div>
            <strong>Salesforce connected users</strong>
            <div>
              {salesforcePricing}
            </div>
          </div>
          <div>
            <input required="required" min="0" max="10000" type="number" className="centro-input-users" value={this.state.users} onChange={this.setUsers} />
          </div>
        </div>
        <div className="centro-payment-section">
          <div>
            <strong>Slack workspace users</strong>
            <div>
              {slackPricing}
            </div>
          </div>
          <div>
            <input required="required" min="0" max="10000" type="number" className="centro-input-users" value={this.state.workspaceUsers} onChange={this.setWorkspaceUsers} />
          </div>
        </div>

        <div className="centro-payment-section">
          <div className="centro-summary">
            <h2>Summary:</h2>
            <div>
              {summaryPackages}
            </div>
            <div>
              {summaryUsers}
            </div>
            <div>
              {summarySlackUsers}
            </div>
            <div>
              {summaryTotal}
            </div>
          </div>
          <div className="centro-submit">
            <ButtonIcon disabled={disabled} onClick={this.submitClick} label="Submit">Submit Order</ButtonIcon>
          </div>
        </div> */}



      </div>

    </div>
  }
}

export default Payment;