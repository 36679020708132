import React, { Component } from 'react';
import ButtonIcon from "./ButtonIcon";
import './PaymentMethod.css';

class PaymentMethod extends Component {


  render() {
    const data = this.props.data;
    const pmid = data && data.id;
    const card = data && data.card;
    const brand = card && data.card.brand;
    const last4 = (card && data.card.last4) || (data && data.last4);
    const exp_month = card && data.card.exp_month;
    const exp_year = card && data.card.exp_year;
    const is_bank = data && data.object && data.object === "bank_account";
    const bank_name = data && data.bank_name;

    // const billing_details = card && data.billing_details;
    // const name = billing_details && billing_details.name;
    // const email = billing_details && billing_details.email;

    // const address = billing_details && billing_details.address;
    // const city = address && address.city;
    // const country = address && address.country;
    // const line1 = address && address.line1;
    // const line2 = address && address.line2;
    // const postal_code = address && address.postal_code;
    // const state = address && address.state;

    // [
    //   {
    //    "id": "ba_1JGD97JPep7RdiFsZhHTP3MO",
    //    "object": "bank_account",
    //    "account_holder_name": null,
    //    "account_holder_type": null,
    //    "bank_name": "STRIPE TEST BANK",
    //    "country": "US",
    //    "currency": "usd",
    //    "customer": "cus_Ju1BSYSV9VjIEv",
    //    "fingerprint": "I9TrCO7tGZkyvl9p",
    //    "last4": "6789",
    //    "metadata": {},
    //    "routing_number": "110000000",
    //    "status": "verified"
    //   }
    //  ]

    const expiryDate = new Date(parseInt(exp_year, 10), parseInt(exp_month, 10) - 1, 1);
    const today = new Date();
    let isExpired = false;
    if (expiryDate < today) {
      isExpired = true;
    }

    return <div className="centro-payment-method">
      <div className="centro-flex-horizontal">
        {is_bank === true ?
          <div><span role="img" aria-label="bank">🏦</span> <span className="centro-card-brand">{bank_name}</span> ending in {last4}</div>
          :
          <div>
            <div><span role="img" aria-label="card">💳</span> <span className="centro-card-brand">{brand}</span> ending in {last4}</div>
            <div className={isExpired ? "centro-expired" : ""}>{isExpired ? "expired" : "expires"} {exp_month}/{exp_year}</div>
          </div>
        }
        <div>
          <ButtonIcon onClick={() => this.props.onRemove(pmid, last4)} className="centro-remove-button" label="Remove">Remove</ButtonIcon>
        </div>
      </div>
      {/* <div className="centro-flex-horizontal">
        <div>
          <h4>Name on card</h4>
          <div>{name}</div>
          <div>{email}</div>
        </div>
        <div>
          <h4>Billing address</h4>
          <div>{line1}</div>
          <div>{line2}</div>
          <div>{city}, {state} {postal_code}</div>
          <div>{country}</div>
        </div>
      </div> */}

    </div>
  }
}

export default PaymentMethod;