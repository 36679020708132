import React, { Component } from 'react';
import Menu from "./Menu";
import "./MergeTagMenu.css";

const MERGEITEMS = {
  LEAD: {
    Company: "Company",
    Email: "Email",
    FirstName: "First Name",
    LastName: "Last Name",
    Name: "Full Name",
    Phone: "Phone",
    Status: "Status",
    Title: "Title"
  },
  USER: {
    company: "Company",
    email: "Email",
    real_name: "Full Name",
    first_name: "First Name",
    last_name: "Last Name",
    phone: "Phone",
    title: "Title"
  }
};

class MergeTagMenu extends Component {

  constructor(props) {
    super(props);
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem(headerName, itemName) {
    if (typeof(this.props.onSelectItem) === "function") {
      if (headerName !== "LEAD") {
        itemName = [headerName, itemName].join(".");
      }
      this.props.onSelectItem(itemName);
    }
  }

  render() {
    let menuItems = [];
    for (let headerName in MERGEITEMS) {
      let subItems = MERGEITEMS[headerName];
      let subItemElements = [];
      for (let itemName in subItems) {
        subItemElements.push(<li key={itemName}><button type="button" onClick={() => this.selectItem(headerName, itemName)}>{subItems[itemName]}</button></li>);
      }
      menuItems.push(<section key={headerName}>
        <h2>{headerName}</h2>
        <ul>{subItemElements}</ul>
      </section>);
    }
    return <Menu remainOpen={true} className="centro-merge-menu" iconName="insert_tag_field">
      <div key="menuItems">
        {menuItems}
      </div>
      <div key="menuButton">
        Merge Tags
      </div>
    </Menu>
  }
}

export default MergeTagMenu;