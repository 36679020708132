import React, { Component } from "react";
import ButtonIcon from "./ButtonIcon";
import FireworksCanvas from "./FireworksCanvas";
import "./Installed.css";

const getAppUrl = (useHttps) => {
  let urlParams = new URLSearchParams(window.location.search);
  const teamid = encodeURIComponent(urlParams.get("team"));
  const appid = encodeURIComponent(urlParams.get("app"));
  const slackUrl = `slack://app?team=${teamid}&id=${appid}&tab=home`;
  const appUrl = `https://slack.com/app_redirect?team=${teamid}&app=${appid}&tab=home`;
  return useHttps ? appUrl : slackUrl;
};

class Installed extends Component {
  constructor(props) {
    super(props);
    this.buttonClick = this.buttonClick.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  buttonClick() {
    // window.open will blur this window even if the app url is not supported
    window.open(getAppUrl());
    // if window comes back in focus prompt to navigate to web client
    window.addEventListener("blur", this.blur);
    window.addEventListener("focus", this.focus);
  }

  blur() {
    clearTimeout(this.timer);
  }

  focus() {
    this.timer = setTimeout(() => {
      window.removeEventListener("focus", this.focus);
      // slack:// may not be supported so prompt user if they want to navigate using http url
      if (window.confirm("Navigate to Centro using the Slack web client?")) {
        window.location = getAppUrl(true);
      }
    }, 100);
  }

  getUrlParameter(id) {
    let urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(id);
  }

  render() {
    const id = this.getUrlParameter("id");
    const error = this.getUrlParameter("error") || "";
    const message = this.getUrlParameter("message") || "Welcome to Centro!";
    const hasMessage = typeof (this.getUrlParameter("message")) === "string";
    const userid = this.getUrlParameter("userid");

    return <div className="centro-win-container">
      <div className="center">
        {id === "salesforce" ?
          <div>
            {error && error.length > 0 ?
              <div><h1>Error Authenticating</h1><h3>{error}</h3></div>
              :
              <div>
                <h1>Connected to Salesforce!</h1>
                <h4 style={{ "text-align": "center" }}>You can close this window.</h4>
              </div>
            }
          </div>
          :
          id === "gmail" ?
            <div>
              <h1>Connected to Gmail!</h1>
              <h4 style={{ "text-align": "center" }}>You can close this window.</h4>
            </div>
            :
            userid !== null ?
              <div>
                <h1>Thanks for Authorizing Grok!</h1>
                <h3>Centro will now Grok threads that mention you!</h3>
                <h4 style={{ "text-align": "center" }}>You can close this window.</h4>

              </div>
              :
              <div>
                <h1>{message}</h1>
                {hasMessage === true ?
                  <div></div>
                  :
                  <div className="message">
                    <h3>Optional steps to get started:</h3>
                    What’s Next?
                    <ul className="whats-next-list">
                      <li><a onClick={this.buttonClick} target="_blank" rel="noopener noreferrer" href="#">Connect to Salesforce</a><br />- Access Salesforce data from Slack</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.centro.rocks/trial-signup-thanks">Install the Salesforce App</a><br />- Access Slack conversations from Salesforce<br />- Send actionable notifications to Slack</li>
                    </ul>
                    Quick Start
                    <ul class="quick-start-list">
                      <li><a target="_blank" rel="noopener noreferrer" href="https://bit.ly/3vIAXas">Configure Slack-to-Case (Service Cloud)</a><br />- Create Salesforce Cases from Slack (~10 minutes)</li>
                    </ul>
                    Need Help?
                    <ul class="help-list">
                      <li><a target="_blank" rel="noopener noreferrer" href="http://support.centro.rocks">Centro Support Center</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://bit.ly/3zHPWE8">Book a meeting</a> with our team to get started quickly!</li>
                    </ul>
                  </div>
                }
              </div>
        }
        {message === "Welcome to Centro!" && !id ?
          <ButtonIcon src="/Slack_Mark.svg" iconName="Layer_1" onClick={this.buttonClick} className="centro-app-home"> Centro App Home</ButtonIcon>
          :
          <div></div>
        }

      </div>
      <FireworksCanvas />
    </div>;
  }
}

export default Installed;
