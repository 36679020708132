

exports.request = (path, method = "GET", body, contentType = "application/json") => {
  let options = {
    method: method,
    headers: {}
  };
  if (method.toLowerCase() !== "get") {
    options.method = method;
    if (body !== undefined) {
      options.body = body;
      options.headers["Content-Type"] = contentType;
    }
  }
  const req = new Request(path, options);
  return new Promise((resolve, reject) => {
    window.fetch(req).then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        if (resp.status !== 204 && resp.headers && resp.headers.get("Content-Type") && resp.headers.get("Content-Type").indexOf("json") > -1) {
          resolve(resp.json());
        }
        else {
          // Non-json result type (i.e. images etc)
          resolve(resp);
        }
      }
      else {
        reject(resp);
      }
    }).catch(e => reject(e));
  });
};

exports.createQueryString = (jsMap = {}) => {
  let ret = [];
  for (let name in jsMap) {
    if (jsMap.hasOwnProperty(name) && jsMap[name] !== undefined) {
      ret.push([encodeURIComponent(name), encodeURIComponent(jsMap[name])].join("="));
    }
  }
  return ret.join("&");
};